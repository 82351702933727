<template>
  <div id="FeatureHierarchy">
    <MyheadCompnent></MyheadCompnent>
    <div style="height: 400px;">
      <treeselect v-model="value" :multiple="true" :options="options" />
    </div>
    <h1>job</h1>
    <el-tree :data="options" :props="props" :height="208" @node-click="handleNodeClick"></el-tree>
    <!-- <el-tree-v2 :data="options" :props="props" :height="208"></el-tree-v2> -->
    <el-row :gutter="20" v-show="show">
      <el-col :span="16">
        <div class="block center">
          <div id="reactions" class="block center" style="border: 1px solid black; height: 600px; width: 600px;">
            <!-- <div id="reactionTemp" class="center" style="height: 600px; width: 600px; background-color: aqua;"></div> -->
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <h1>job</h1>
      </el-col>
    </el-row>


    <!-- <treeselect v-model="value" :multiple="true" :options="options" /> -->
    <MyfooterCompnent></MyfooterCompnent>
  </div>
</template>

<script>

  import MyheadCompnent from '@/components/Common/MyheadCompnent.vue'
  import MyfooterCompnent from '@/components/Common/MyfooterCompnent.vue'
  import Treeselect from 'vue3-treeselect'
  import 'vue3-treeselect/dist/vue3-treeselect.css'
  import vis from "vis-network/dist/vis-network.min.js"
  import "vis-network/dist/dist/vis-network.min.css"
  // Vue.use(LiquorTree)
  export default {
    name: 'FeatureHierarchy',
    data () {
      return {
        show: false,
        value: null,
        // define options
        options: [{
          id: 'a',
          label: 'a',
          children: [{
            id: 'aa',
            label: 'aa',
          }, {
            id: 'ab',
            label: 'ab',
          }],
        }, {
          id: 'b',
          label: 'b',
        }, {
          id: 'c',
          label: 'c',
        }],
        props: {
          value: 'id',
          label: 'label',
          children: 'children',
        },
        node: [
          {
            "id": "a",
            "label": "aa"
          },
          {
            "id": "b",
            "label": "bb"
          },
          {
            "id": "c",
            "label": "cc"
          },
        ],
        edge: [
          {
            "from": "a",
            "to": "b",
            "label": "ab",
          },
          {
            "from": "a",
            "to": "c",
            "label": "ac",
          },
        ],
        options2: {
          nodes: {
            borderWidth: 1,
            font: {
              size: 10
            },
          },
          edges: {
            arrows: {
              to: {
                enabled: true,
                type: "arrow",
              },
            },
            width: 3
          },
          physics: {
            stabilization: false,
            barnesHut: {
              gravitationalConstant: -1000,
              springConstant: 0.01,
              springLength: 50,
              avoidOverlap: 0.0,
              centralGravity: 0.5,
            },
          },
          layout: {
            randomSeed: 2,
            improvedLayout: false,
          },
          interaction: {
            tooltipDelay: 200,
            hideEdgesOnDrag: true,
            // hideEdgesOnZoom: true,
          },
        }
      }
    },
    methods: {
      handleNodeClick (data) {
      },
      plotNetwork (nodeList, edgeList, options) {
        this.show = true
        this.$nextTick(function () {
          var presentElement = document.getElementById("reactions")
          var creatElement = document.createElement("div")
          creatElement.id = "reactionTemp"
          creatElement.style.height = "600px"
          creatElement.style.width = "600px"
          creatElement.class = "center"
          presentElement.appendChild(creatElement)
          var container = document.getElementById("reactionTemp");
          nodeList = new vis.DataSet(nodeList)
          edgeList = new vis.DataSet(edgeList)
          var data = {
            nodes: nodeList,
            edges: edgeList,
          };
          var network = new vis.Network(container, data, options)
        })

      }
    },
    components: {
      Treeselect,
      MyheadCompnent,
      MyfooterCompnent,
    },
    mounted () {
      this.plotNetwork(this.node, this.edge, this.options2)
    }
  }
</script>

<style scoped lang="scss">
  .center {
    text-align: middle;
    vertical-align: middle;
  }
</style>